import React, { useState, useEffect } from 'react';
import { Layout, Spin, Row, Col, Image } from 'antd';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import { serverUrl } from './config';
import Logo from './components/Logo';
import InfoPage from './screens/InfoPage';
import LoginPage from './screens/LoginPage';

import './App.less';

const { Header, Footer, Content } = Layout;

const App = () => {
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [cookies, setCookie] = useCookies(['session']);

  useEffect(() => {
    let session = cookies.session;

    if (!session ){
      setIsAuth(false);
      setIsLoadingPage(false);
      return;
    }
    axios({
      url: serverUrl,
      method: "GET",
      params: {
        function: "checklogin",
        session: session
      }
    })
    .then(response => {
      if(!response.data.login) throw new Error("Session wrong or expired");

      setIsLoadingPage(false);
      setIsAuth(true);
    })
    .catch(error => {
      const { response } = error;
      if (response) {
        const { request, ...errorObject } = response; // take everything but 'request'
        console.log(errorObject);
        //return openNotification("warning", "Login Error", errorObject.data.message);
      }
      else {
        //return openNotification("error", "Login Error", error.message);
      }
      setIsLoadingPage(false);
      setIsAuth(false);
    })
  }, [])
  return (
    <Layout className="editting">
      <Header className="alotech-navbar">
        <Logo />
      </Header>

      <Content >
      <Row justify="center" >
        <Col span="12" xs={24} md={24} lg={24}>
        {
          !isLoadingPage ? 
            (
              !isAuth ?  
              <LoginPage setIsAuth={setIsAuth} setCookie={setCookie} /> : 
              <InfoPage setIsAuth={setIsAuth} setCookie={setCookie} />
            ) :
            (
              <div className="alotech-loading-container">
                <Spin className="alotech-loading-container-spinner" size="large"  />
              </div>
            )
        }
        </Col>
      </Row>               
      </Content>  
      <Footer className="footers">
        <Image
        style={{float: 'right'}}
        src="alotech-logo.png"
        />
      </Footer> 
    </Layout>
  );

}

export default App;