import React, {useState} from 'react';
import moment from 'moment';
import { Skeleton, PageHeader, Button, DatePicker, Space, notification, Layout, Table, Input } from 'antd';
import { FilterOutlined, FileExcelOutlined, LogoutOutlined } from '@ant-design/icons';
import './AloBody.less';
const { RangePicker } = DatePicker;
const xlsx = require('xlsx');
const axios = require("axios");

const DetailReportBody = (props) => {
  var [useData, setData] = useState([]);
  var [search, setSearch] = useState("");
  var [startDate, setstartDate] = useState("");
  var [finishDate, setfinishDate] = useState("");
  const apiColumns = [
    {
      title: 'Arayan Numara',
      dataIndex: 'callerid',
    },
    {
      title: 'Çağrı Tarihi',
      dataIndex: 'calldate',
    },
    {
      title: 'Barkod Numarası',
      dataIndex: 'barcodeno',
    }
  ];
  
  function onChange(date, dateString) {
      startDate = dateString[0];
      finishDate = dateString[1]; 
      setstartDate(startDate);
      setfinishDate(finishDate);
  }

  const filterSearch = async() => {
    if(!(startDate || finishDate || search)){
      return openNotification("warning","Filter Error", "Tarih, Barkod No, Arayan Numara veya Müşteri Numarası değerlerinden birini giriniz!");
    }

    if(!startDate || !finishDate) {
      finishDate = moment(Date.now()).format('YYYY-MM-DD');
      startDate = moment(Date.now()).subtract(1, 'year').format('YYYY-MM-DD');
    }

    if(startDate > finishDate) {return openNotification("warning","Filter Error", "Başlangıç tarihi bitiş tarihinden küçük olmak zorunda!");}

    var response = await axios({
      url: "/notCalls",
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        startDate: startDate,
        finishDate: finishDate,
        search: search
      },
      json: true
    });

    if(response.data.length == 0) {return openNotification("warning","Filter Error", "Seçtiğiniz tarih aralığında kayıt bulunamadı!");}
 
    const data = response.data.map((dataJson) => ({
      callerid: dataJson.callerid,
      calldate: moment(dataJson.calldate.value).subtract(3, "hours").format("YYYY-MM-DD HH:mm"),
      barcodeno: JSON.parse(dataJson.customvariables).followUpId
    }));
      setData(data)
  }



  function exportXlsx() {
    var noData = document.querySelector("#detailTable > div > div > table > tbody > tr > td > div > p");
    if(noData) {return openNotification("warning","Export Error", "Lütfen önce raporu oluşturun!");}

    var apiDatas = [];

    for(var i = 0; i < useData.length; i++) {
      var apiData = {};

      apiData['Arayan Numara'] = useData[i].callerid;
      apiData['Çağrı Tarihi'] = useData[i].calldate;
      apiData['Barkod Numarası'] = useData[i].barcodeno;
      apiDatas.push(apiData);
    }

    var ws = xlsx.utils.json_to_sheet(apiDatas);
    var wb = xlsx.utils.book_new();

    xlsx.utils.book_append_sheet(wb, ws, "Detay Rapor");

    xlsx.writeFile(wb, "yapikredi-alotech-rapor.xlsx");
  }

  const openNotification = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
      placement: "topRight"
    });
  };

  return (
      !props.loading ? 
        (
          <Layout>
            <PageHeader>
              <Space>
                <RangePicker  onChange={onChange} />
                <Input  type="text" name="search" id="search" onChange={e => setSearch(e.target.value)} placeholder="Barcode-Caller-Called" />
                <Button type="primary" icon={<FilterOutlined />} onClick={() => filterSearch()}>
                      Filter
                </Button>
                <Button type="primary" icon={<FileExcelOutlined />} onClick={() => exportXlsx()}>
                      Export
                </Button>
              </Space>
              <Button type="danger" icon={<LogoutOutlined />} style={{float: 'right'}} onClick={()=>{props.setCookie("session", ".");props.setIsAuth(false);}}>
                  Logout
              </Button>                
            </PageHeader>
            
            <Table 
              id="notCalls"
              columns={apiColumns} 
              dataSource={useData}
              pagination={{ pageSize : 10 }}
            />
          </Layout>
        )
        :
        <Skeleton active /> 
  );  
}

export default DetailReportBody;