import React from 'react';
import { Skeleton, PageHeader, Button, DatePicker, Space, notification } from 'antd';
import { FilterOutlined, FileExcelOutlined, LogoutOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const xlsx = require('xlsx');
const axios = require("axios");

const PageHead = (props) => {
  return (
    !props.loading ? 
      (<PageHeader>
        <Space>
          <RangePicker />
          
          <Button type="primary" icon={<FilterOutlined />} onClick={() => filterDate()}>
                Filter
          </Button>

          <Button type="primary" icon={<FileExcelOutlined />} onClick={() => exportXlsx()}>
                Export
          </Button>
        </Space>
        
        <Button type="danger" icon={<LogoutOutlined />} style={{float: 'right'}} onClick={()=>{props.setCookie("session", ".");props.setIsAuth(false);}}>
            Logout
        </Button> 
      </PageHeader> )
      :
      <Skeleton active />
  ); 
}

async function filterDate() {
  var startDate = document.getElementsByTagName("input")[0].value;
  var finishDate = document.getElementsByTagName("input")[1].value;

  if(!startDate || !finishDate) {return openNotification("warning","Filter Error", "Lütfen tarih seçin!");}

  if(startDate > finishDate) {return openNotification("warning","Filter Error", "Başlangıç tarihi bitiş tarihinden küçük olmak zorunda!");}

  var response = await axios({
    url: "/query",
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      startDate: startDate,
      finishDate: finishDate
    },
    json: true
  });

  if(response.data.length == 0) {return openNotification("warning","Filter Error", "Seçtiğiniz tarih aralığında kayıt bulunamadı!");}

  var apiTable = document.getElementsByTagName("table")[0];
  var callTable = document.getElementsByTagName("table")[1];
  var errCodeTable = document.getElementsByTagName("table")[2];

  for (var i = apiTable.rows.length; i > 1; i--) {
    apiTable.deleteRow(1);
    callTable.deleteRow(1);
    errCodeTable.deleteRow(1);
  }

  for (var i = 0; i < response.data.length; i++) {
    var apiRow = apiTable.insertRow(apiTable.rows.length);

    var apiRate = response.data[i].success / response.data[i].total_call * 100;

    createCell(apiRow.insertCell(0), response.data[i].tarih.value, 'row');
    createCell(apiRow.insertCell(1), (response.data[i].total_call - response.data[i].error_yapikredi ), 'row');
    createCell(apiRow.insertCell(2), response.data[i].success, 'row');
    createCell(apiRow.insertCell(3), response.data[i].error_alotech, 'row');
    createCell(apiRow.insertCell(4), `% ${apiRate.toFixed(0)}`, 'row');
  }
  
  for (var i = 0; i < response.data.length; i++) {
    var callRow = callTable.insertRow(callTable.rows.length);

    var callRate = response.data[i].answer / response.data[i].success * 100;
    var otherStatu = response.data[i].success - response.data[i].answer - response.data[i].busy - response.data[i].no_answer - response.data[i].cancel;
    
    createCell(callRow.insertCell(0), response.data[i].tarih.value, 'row');
    createCell(callRow.insertCell(1), response.data[i].success, 'row');
    createCell(callRow.insertCell(2), response.data[i].answer, 'row');
    createCell(callRow.insertCell(3), response.data[i].busy, 'row');
    createCell(callRow.insertCell(4), response.data[i].no_answer, 'row');
    createCell(callRow.insertCell(5), response.data[i].cancel, 'row');
    createCell(callRow.insertCell(6), otherStatu, 'row');
    createCell(callRow.insertCell(7), `% ${callRate.toFixed(0)}`, 'row');
  }

  for (var i = 0; i < response.data.length; i++) {
    var errCodeRow = errCodeTable.insertRow(errCodeTable.rows.length);

    var otherErrCodes = response.data[i].error_yapikredi - response.data[i].yk_errcode_191 - response.data[i].yk_errcode_59 - response.data[i].yk_errcode_192 - response.data[i].yk_errcode_126;
    
    createCell(errCodeRow.insertCell(0), response.data[i].tarih.value, 'row');
    createCell(errCodeRow.insertCell(1), response.data[i].error_yapikredi, 'row');
    createCell(errCodeRow.insertCell(2), response.data[i].yk_errcode_191, 'row');
    createCell(errCodeRow.insertCell(3), response.data[i].yk_errcode_59, 'row');
    createCell(errCodeRow.insertCell(4), response.data[i].yk_errcode_192, 'row');
    createCell(errCodeRow.insertCell(5), response.data[i].yk_errcode_126, 'row');
    createCell(errCodeRow.insertCell(6), otherErrCodes, 'row');
  }
}

function exportXlsx() {
  var noData = document.getElementsByTagName("p");
  if(noData.length > 0) {return openNotification("warning","Export Error", "Lütfen önce raporu oluşturun!");}

  var apiTable = document.getElementsByTagName("table")[0];
  var callTable = document.getElementsByTagName("table")[1];
  var errCodeTable = document.getElementsByTagName("table")[2];

  var apiDatas = [];
  var callDatas = [];
  var errCodeDatas = [];

  for(var i=1; i < apiTable.rows.length; i++) {
    var apiData = {};

    apiData['Tarih'] = apiTable.rows[i].cells[0].innerText;
    apiData['Toplam İstek'] = apiTable.rows[i].cells[1].innerText;
    apiData['Başarılı İstek'] = apiTable.rows[i].cells[2].innerText;
    apiData['Başarısız İstek'] = apiTable.rows[i].cells[3].innerText;
    apiData['AloTech Erişim Yok'] = apiTable.rows[i].cells[4].innerText;
    apiData['Servis Başarı Durumu'] = apiTable.rows[i].cells[5].innerText;

    apiDatas.push(apiData);
  }

  for(var i=1; i < callTable.rows.length; i++) {
    var callData = {};

    callData['Tarih'] = callTable.rows[i].cells[0].innerText;
    callData['Toplam Arama'] = callTable.rows[i].cells[1].innerText;
    callData['Answer'] = callTable.rows[i].cells[2].innerText;
    callData['Busy'] = callTable.rows[i].cells[3].innerText;
    callData['No Answer'] = callTable.rows[i].cells[4].innerText;
    callData['Cancel'] = callTable.rows[i].cells[5].innerText;
    callData['Ulaşılamadı'] = callTable.rows[i].cells[6].innerText;
    callData['Gerçekleşen Görüşme Oranı'] = callTable.rows[i].cells[7].innerText;

    callDatas.push(callData);
  } 

  for(var i=1; i < errCodeTable.rows.length; i++) {
    var errCodeData = {};

    errCodeData['Tarih'] = callTable.rows[i].cells[0].innerText;
    errCodeData['Toplam Hata Kodu'] = callTable.rows[i].cells[1].innerText;
    errCodeData['Paket Kapanmıştır'] = callTable.rows[i].cells[2].innerText;
    errCodeData['Gönderi Bulunamadı'] = callTable.rows[i].cells[3].innerText;
    errCodeData['GSM Bulunamadı'] = callTable.rows[i].cells[4].innerText;
    errCodeData['FollowUpId Yok'] = callTable.rows[i].cells[5].innerText;
    errCodeData['Diğer Statüler'] = callTable.rows[i].cells[6].innerText;

    errCodeDatas.push(errCodeData);
  } 


  var ws = xlsx.utils.json_to_sheet(apiDatas);
  var ws2 = xlsx.utils.json_to_sheet(callDatas);
  var ws3 = xlsx.utils.json_to_sheet(errCodeDatas);
  var wb = xlsx.utils.book_new();

  xlsx.utils.book_append_sheet(wb, ws, "Servis Başarı Durumu");
  xlsx.utils.book_append_sheet(wb, ws2, "Arama Sonuçları");
  xlsx.utils.book_append_sheet(wb, ws3, "Hata Kodları");

  xlsx.writeFile(wb, "yapikredi-alotech-rapor.xlsx");
}

const openNotification = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
    placement: "topRight"
  });
};

function createCell(cell, text, style) {
  var txt = document.createTextNode(text);
  cell.classList.add("ant-table-cell");
  cell.appendChild(txt);                   
} 

export default PageHead;