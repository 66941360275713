import React, { useState } from 'react';
import { Modal, Statistic,Card, Skeleton, Form, Input, Button, notification } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

import axios from 'axios';
import { serverUrl } from '../config';


const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const { Countdown } = Statistic;

const LoginPage = (props) => {

    const { setIsAuth, setCookie } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [loginMessage, setLoginMessage] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [otpValue, setOtpValue] = useState(undefined);
    const [showResendCode, setShowResendCode] = useState(false);
    const [otpApp, setotpApp] = useState(false);
    const [deadline, setDeadline] = useState(Date.now() + 1000 * 60 * 3);

    const handleResendCodeClick = async (e) => {
      e.preventDefault();
      try {
        const response = await axios({
          url: serverUrl,
          method: "GET",
          params: {
            email: username,
            function: "request_otp_code",
          },
          timeout: 5000,
        });
  
        if(response.status === 200 && response.data.success) {
          setShowResendCode(false);
          setDeadline(Date.now() + (response?.data?.remaining_time || 180) * 1000);
          if(response.data.message) {
            openNotification(response?.data?.message);    
          }
        }
      } catch (err) {
        openNotification(err.message);
      }
    };
    const openNotification = (type, title, message) => {
      notification[type]({
        message: title,
        description: message,
        placement: "topRight"
      });
    };
    const handleTextareaChange = (e) => {
      setOtpValue(e.target.value);
    };
    const handleCancel = () => {
      setShowResendCode(false);
      setIsModalOpen(false);
    };
  
    const onFinish = () => {
      setShowResendCode(true);
    };
    const handleCheckLogin = async function () {
      try {
        setIsLoading(true);
        const loginCheck = await axios({
          url: serverUrl,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          params: {
            function: "login_check"
          },
          data:{
            email: username,
            password: password
          },
          timeout: 5000
        });
  
        if (loginCheck.status == 200 && loginCheck.data.login_check && loginCheck.data.otp_method && loginCheck.data.otp_method === "otp_app") {
          setIsModalOpen(true);
          setLoginMessage("");
          setIsLoading(false);
          setotpApp(true);
          console.log(showResendCode)
        } else if (loginCheck.status == 200 && loginCheck.data.login_check && loginCheck.data.otp) {
          setDeadline(Date.now() + 1000 * 60 * 2)
          setIsModalOpen(true);
          setLoginMessage("");
          setIsLoading(false);
        } else if (loginCheck.status == 200 && loginCheck.data.login_check && !loginCheck.data.otp) {
          HandleLogin();
        } else {
          setIsLoading(false);
          setLoginMessage(loginCheck.data.message);
        }
      } catch (error) {
        openNotification(error.message);      }
    }
    const HandleLogin = async function () {
      if (!username) {return openNotification("warning","Login Error", "Username parameter is missing!");}
      if (!password) {return openNotification("warning","Login Error", "Password parameter is missing!");}
      
      setIsLoading(true);

      try {
        const response = await axios({
          url: serverUrl,
          method: "GET",
          params: {
            function: "login",
            email: username,
            password: password,
            otp: otpValue
          }
        });

        if(!response.data.login) {
          const message = response.data?.message ? response.data?.message:"Email or password is wrong";
          throw new Error(message);
        }

        setIsLoading(false);

        const session = await response.data.session;
        setCookie("session", session, { maxAge: 86400 });
        setIsAuth(true);

        openNotification("info", "Login successed!");
      } catch(error) {
        setIsLoading(false);

        const { response } = error;
        if (response) {
          const { request, ...errorObject } = response; // take everything but 'request'
          console.log(errorObject);
          return openNotification("warning", "Login Error", errorObject.data.message);
        }
        else {
          return openNotification("error", "Login Error", error.message);
        }
      }
    }

    return (
        
      <Card title="Login" bordered={false} style={{ width: 400, margin: "auto", marginTop: 20}}>
        { 
          isLoading ? 
          <div>
            <Skeleton.Input style={{ width: 300, marginBottom: 24 }} active size="default" />
            <Skeleton.Input style={{ width: 300, marginBottom: 24 }} active size="default" />
            <Skeleton.Input style={{ width: 300, marginBottom: 24 }} active size="default" />
            <Skeleton.Input style={{ width: 300, marginBottom: 24  }} active size="default" />
            <Skeleton.Button style={{ width: 60, marginBottom: 24 }} active size="default" />
          </div>
          :
          
          <form onSubmit={handleCheckLogin}>
          <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input onChange={e => setUsername(e.target.value)} />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password onChange={e => setPassword(e.target.value)} />
            </Form.Item>
            <Modal title="Verification Code" open={isModalOpen} onOk={HandleLogin} onCancel={handleCancel}  cancelButtonProps={{style: { backgroundColor: '#f5222d', color: '#ffffff' }}} okButtonProps={{style: { backgroundColor: '#52c41a', color: '#ffffff' }}}>
              <p>Please enter the verification code sent to your Phone or E-Mail address</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  value={otpValue}
                  onChange={handleTextareaChange}
                  style={{ marginRight: 10 }}
                />
                 {!otpApp ?
                  ( showResendCode ? (<a href="#" style={{ color: '#6d809f', fontSize: '12px' }} onClick={handleResendCodeClick}>ResendCode</a>)
                  : (<Countdown format="mm:ss" valueStyle={{ fontSize: '12px', color: '#fd622c' }} value={deadline} onFinish={onFinish}/>)) 
                  : (<span></span>)}
              </div>
            </Modal>
            <Form.Item {...tailLayout}>
              <Button type="primary" icon={<LoginOutlined />} onClick={e => handleCheckLogin()}>
                Login
              </Button>
            </Form.Item>
          </Form>
          </form>
        }
      </Card>
    );
}

export default LoginPage;