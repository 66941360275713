import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Tabs } from 'antd';

import '../App.less';
import AloHeader from '../components/AloHeader';
import AloBody from '../components/AloBody';
import DetailReportBody from '../components/DetailReportBody';
import NotCalls from '../components/NotCalls';

import axios from 'axios';
import { serverUrl } from '../config';
const { TabPane } = Tabs;

const InfoPage = (props) => {

    const [header, setHeader] = useState({
      title: "",
      routes: []
    });
    
    const [loading, setLoading] = useState(true);
    const [cookies, setCookie] = useCookies(['session']);
  
    useEffect(() => {
      let session = cookies.session;
      if (!loading) return;
      
      axios({
        url: serverUrl,
        params: {
          function: "checklogin",
          session: session
        }
      })
      .then((response) => {
        let data = response.data;

        let routes = [];
        for( let [key, value] of Object.entries(data)) {
          if(key!=="index" && typeof value == "object") routes.push(value);
        }
        setLoading(false);
        setHeader({
          routes: routes
        });   
        })
        .catch(err => {
          console.log(err.message);
        })
    });

    return (
      <>
      <Tabs defaultActiveKey="1" style={{marginLeft: '20px'}}>
        <TabPane tab="Report" key="1">
          <AloHeader
            header={header}
            loading={loading}
            setCookie={setCookie}
            setIsAuth={props.setIsAuth}
          />
          <AloBody 
            header={header}
            loading={loading}
          />
        </TabPane>
        <TabPane tab="Detail Report" key="2">
          <DetailReportBody 
            header={header}
            loading={loading}
            setCookie={setCookie}
            setIsAuth={props.setIsAuth}
          />
        </TabPane>
        <TabPane tab="Not Calls Ringing" key="3">
          <NotCalls 
            header={header}
            loading={loading}
            setCookie={setCookie}
            setIsAuth={props.setIsAuth}
          />
        </TabPane>
    </Tabs>
        
        </>
    );
}

export default InfoPage;