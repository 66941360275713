import React from 'react';
import { Skeleton, Layout , Table} from 'antd';

import './AloBody.less';

const PageBody = (props) => {
    return (
        !props.loading ? 
          (
            <Layout>
              <Table 
                id="apiTable"
                columns={apiColumns} 
                dataSource={apiData} 
              />
              <Table 
                id="callTable"
                columns={callColumns} 
                dataSource={callData} 
              />
              <Table 
                id="errCodeTable"
                columns={errCodeColumns} 
                dataSource={errCodeData} 
              />
            </Layout>
          )
          :
          <Skeleton active />
    );  
}

const apiColumns = [
  {
    title: 'Tarih',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Toplam İstek',
    dataIndex: 'totalRequest',
    key: 'totalRequest',
  },
  {
    title: 'Başarılı İstek',
    dataIndex: 'successRequest',
    key: 'successRequest',
  },
  {
    title: 'AloTech Erişim Yok',
    dataIndex: 'alotechServerError',
    key: 'alotechServerError',
  },
  {
    title: 'Servis Başarı Durumu',
    dataIndex: 'successRate',
    key: 'successRate',
  },
];

const callColumns = [
{
  title: 'Tarih',
  dataIndex: 'date',
  key: 'date',
},
{
  title: 'Toplam Arama',
  dataIndex: 'totalCall',
  key: 'totalCall',
},
{
  title: 'Answer',
  dataIndex: 'answer',
  key: 'answer',
},
{
  title: 'Busy',
  dataIndex: 'busy',
  key: 'busy',
},
{
  title: 'No Answer',
  dataIndex: 'noAnswer',
  key: 'noAnswer',
},
{
  title: 'Cancel',
  dataIndex: 'cancel',
  key: 'cancel',
},
{
  title: 'Ulaşılamadı',
  dataIndex: 'noReached',
  key: 'noReached',
},
{
  title: 'Gerçekleşen Görüşme Oranı',
  dataIndex: 'callRate',
  key: 'callRate',
},
];

const errCodeColumns = [
  {
    title: 'Tarih',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Toplam Hata Kodu',
    dataIndex: 'totalErr',
    key: 'totalErr',
  },
  {
    title: 'Paket Kapanmıştır',
    dataIndex: 'errCode191',
    key: 'errCode191',
  },
  {
    title: 'Gönderi Bulunamadı',
    dataIndex: 'errCode59',
    key: 'errCode59',
  },
  {
    title: 'GSM Bulunamadı',
    dataIndex: 'errCode192',
    key: 'errCode192',
  },
  {
    title: 'FollowUpId Yok',
    dataIndex: 'errCode126',
    key: 'errCode126',
  },
  {
    title: 'Diğer Statüler',
    dataIndex: 'otherErrCodes',
    key: 'otherErrCodes',
  }
];

const apiData = [
  
];

const callData = [

];

const errCodeData = [

];

export default PageBody;